import {SelectInput, DateTimeInput, Edit, SimpleForm, NumberInput} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import * as React from "react";
import {JsonInput} from "react-admin-json-view";

export const ChannelProductPriceEdit = () => (
    <Edit label="Edit Account" mutationMode="pessimistic">
        <SimpleForm sanitizeEmptyValues>
            <JsonInput source="price_data"/>
            <JsonInput source="product_data"/>
            <JsonInput source="ref"/>
            <NumberInput source="run_interval"/>
            <DateTimeInput source="next_run_at"/>
            <SelectInput source="status" choices={[
                {id: 'ready', name: 'Ready'},
                {id: 'processing', name: 'Processing'},
                {id: 'error', name: 'Error'},
                {id: 'paused', name: 'Paused'},
                {id: 'sqs', name: 'Sqs'},
            ]}/>

        </SimpleForm>
    </Edit>
);