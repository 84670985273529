// in src/users.js
import * as React from "react";
import {List, Datagrid, TextField, NumberField, BooleanField, TextInput, ImageField, ReferenceField, ReferenceInput, SelectInput} from 'react-admin';
import {JsonField, JsonInput} from "react-admin-json-view";
import {DeleteStateInput} from "../common/DeleteStateInput";
import {SaledrumPagination} from "../SaledrumPagination";
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <TextInput label="Entity" source="entity" alwaysOn />,
    <TextInput label="Entity ID" source="entity_id" alwaysOn />,
    <TextInput label="User ID" source="user_id" alwaysOn />,
    <ReferenceInput label="Account" source="account_id" reference="accounts" alwaysOn/>,
    <DeleteStateInput label="Delete state" alwaysOn />,
];
export const UserActivityList = () => (
    <List filters={postFilters} pagination={<SaledrumPagination/>} perPage={100}  sort={{ field: 'id', order: 'DESC' }}>
       <Datagrid>
            <TextField source="id" />
            <ReferenceField source="user_id" reference="users" />
            <TextField source="entity_id" />
            <TextField source="action"/>
            <JsonField source="entity_repr" />
            <JsonField source="entity_diff_data" />
            <ReferenceField source="account_id" reference="accounts"/>
            <TextField source="created_at"/>
        </Datagrid>
    </List>
);