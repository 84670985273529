import { SelectInput } from 'react-admin';

export const DeleteStateInput = (props) => {
    if (!props['source']){
        props = {...props, source: 'delete_state'}
    }
    return(
        <SelectInput  source="delete_state" {...props}  choices={[
            { id: 'active', name: 'Active' },
            { id: 'deleted', name: 'Deleted' },
            { id: 'paused', name: 'Paused' },
        ]} alwaysOn/>)
}