// in src/users.js
import * as React from "react";
import {List, Datagrid, TextField, NumberField, ReferenceField, ReferenceInput, SelectInput, TextInput, BulkUpdateButton, BulkDeleteButton} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {SaledrumPagination} from "../SaledrumPagination";
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <ReferenceInput label="Account" source="account_id" reference="accounts" alwaysOn/>,
    <ReferenceInput label="Channel" source="channel_id" reference="channels" alwaysOn/>,
    <DeleteStateInput label="Delete state" alwaysOn />,
    <SelectInput source="type" label="Type" choices={[
                {id: 'amazon_report_qty_price', name: 'amazon_report_qty_price'},
                {id: 'amazon_report_listing_active', name: 'amazon_report_listing_active'},
                {id: 'amazon_report_qty_fba', name: 'amazon_report_qty_fba'},
                {id: 'amazon_report_all_orders', name: 'amazon_report_all_orders'},
                {id: 'amazon_report_listing_inactive', name: 'amazon_report_listing_inactive'},
                {id: 'amazon_report_listing_suppressed', name: 'amazon_report_listing_suppressed'},
                {id: 'amazon_report_listing_defect', name: 'amazon_report_listing_defect'},
            ]} alwaysOn/>,
    <SelectInput label="Status" source="status" choices={[
                {id: 'pending', name: 'Pending'},
                {id: 'processing', name: 'Processing'},
                {id: 'error', name: 'Error'},
            ]} alwaysOn/>,
];
const PostBulkActionButtons = () => (
    <>
        <BulkUpdateButton label="Pause" data={{delete_state: 'paused'}} mutationMode="pessimistic"  />
        <BulkUpdateButton label="Activate" data={{delete_state: 'active'}} mutationMode="pessimistic"  />
        <BulkUpdateButton label="Reset Retry Count" data={{retry_cnt: 1}} mutationMode="pessimistic" />
        <BulkDeleteButton />
    </>
);
export const ChannelJobList = () => (
    <List filters={postFilters} pagination={<SaledrumPagination/>} perPage={100} sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons/>}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <ReferenceField source="account_id" reference="accounts"/>
            <ReferenceField source="channel_id" reference="channels"/>
            <TextField source="type"/>
            <TextField source="kwargs.dataStartTime"/>
            <TextField source="last_run_at"/>
            <TextField source="next_run_at"/>
            <NumberField source="retry_cnt"/>
            <NumberField source="run_interval"/>
            <TextField source="status"/>
            <TextField source="delete_state"/>
            <TextField source="updated_at"/>
        </Datagrid>
    </List>
);