// in src/users.js
import * as React from "react";
import {List, Datagrid, TextField, NumberField, BooleanField, TextInput, ImageField, ReferenceField, ReferenceInput, SelectInput} from 'react-admin';
import {JsonField, JsonInput} from "react-admin-json-view";
import {DeleteStateInput} from "../common/DeleteStateInput";
import {SaledrumPagination} from "../SaledrumPagination";
const postFilters = [
    <TextInput label="Search" source="id" alwaysOn />,
];
export const VirtualProductRestrictionList = () => (
    <List filters={postFilters} pagination={<SaledrumPagination/>} perPage={100}>
       <Datagrid>
            <TextField source="id" />
        </Datagrid>
    </List>
);