// in src/users.js
import * as React from "react";
import {List, Datagrid, TextField, NumberField, ReferenceField, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {SaledrumPagination} from "../SaledrumPagination";

const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <ReferenceInput label="Channel" source="channel_id" reference="channels" alwaysOn/>,
     <SelectInput source="status" choices={[
                {id: 'ready', name: 'Ready'},
                {id: 'processing', name: 'Processing'},
                {id: 'error', name: 'Error'},
                {id: 'paused', name: 'Paused'},
                {id: 'sqs', name: 'Sqs'},
            ]} alwaysOn/>,
];
export const ChannelProductPriceList = () => (
    <List filters={postFilters} pagination={<SaledrumPagination/>} perPage={100}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="channel_type"/>
            <TextField source="last_run_at"/>
            <TextField source="next_run_at"/>
            <TextField source="price_data"/>
            <TextField source="product_data"/>
            <TextField source="ref_ids"/>
            <TextField source="region"/>
            <NumberField source="run_interval"/>
            <TextField source="status"/>
            <ReferenceField source="channel_id" reference="channels"/>
        </Datagrid>
    </List>
);