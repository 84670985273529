import {BooleanInput, DateTimeInput, Edit, SimpleForm, TextInput, SelectInput} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {JsonInput} from "react-admin-json-view";

export const ChannelEdit = () => (
    <Edit label="Edit Account" mutationMode="pessimistic">
        <SimpleForm sanitizeEmptyValues>
            <TextInput source="name"/>
            <TextInput source="channel_external_id"/>
            <TextInput source="region"/>
            <DeleteStateInput source="live_status"/>
            <DeleteStateInput source="reprice_status"/>
            <DeleteStateInput source="delete_state"/>
            <JsonInput source="meta_data"/>
            <JsonInput source="api_data"/>
            <JsonInput source="channel_data"/>
        </SimpleForm>
    </Edit>
);