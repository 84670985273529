import {Edit, SimpleForm, TextInput, SelectInput, DateTimeInput, NumberInput, NumberField, Datagrid} from 'react-admin';
import * as React from "react";

export const VirtualProductEdit = () => {
    return (
        <Edit label="Edit Saved Search" mutationMode="pessimistic" component="div">
            <SimpleForm>
                <NumberInput source="standard_margin_percent" label="standard margin percent (20%)" fullWidth={true}/>
                <NumberInput source="standard_margin_amount" label="standard margin amount ($10)" fullWidth={true}/>
                <NumberInput source="min_percent_percent" label="min margin percent (15%)" fullWidth={true}/>
                <NumberInput source="min_margin_amount" label="min margin amount (8%)" fullWidth={true}/>
                <NumberInput source="max_percent_percent" label="max margin percent (200%)" fullWidth={true}/>
                <NumberInput source="max_margin_amount" label="max margin amount (0)" fullWidth={true}/>
            </SimpleForm>
        </Edit>
    )
};