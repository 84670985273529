// in src/users.js
import * as React from "react";
import {List, Datagrid, TextField, EmailField, TextInput, SelectInput, DateTimeField, NumberField, ReferenceField, ReferenceInput, SimpleForm, BulkUpdateButton, BulkDeleteButton} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {JsonField} from "react-admin-json-view";
import {SaledrumPagination} from "../SaledrumPagination";

const postFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    <ReferenceInput label="Account" source="account_id" reference="accounts" alwaysOn/>,
    <SelectInput source="strategy" choices={[
        {id: 'walmart', name: 'Walmart'},
        {id: 'bestbuy', name: 'BestBuy'},
    ]}/>,
    <SelectInput source="status" choices={[
        {id: 'ready', name: 'Ready'},
        {id: 'processing', name: 'Processing'},
        {id: 'paused', name: 'Paused'},
        {id: 'error', name: 'Error'},
    ]}/>
];
const PostBulkActionButtons = () => (
    <>
        <BulkUpdateButton label="Ready" data={{status: 'ready'}} mutationMode="pessimistic"  />
        <BulkUpdateButton label="Paused" data={{status: 'paused'}} mutationMode="pessimistic"  />
        <BulkDeleteButton />
    </>
);
export const VirtualProductIngestionList = () => (
    <List filters={postFilters} pagination={<SaledrumPagination/>} perPage={100} bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="url"/>
            <TextField source="strategy"/>
            <TextField source="status"/>
            <TextField source="next_run_at"/>
            <TextField source="last_run_at"/>
            <NumberField source="fetch_interval"/>
            <NumberField source="retry_cnt"/>
            <JsonField source="meta_data"/>
            <JsonField source="pagination"/>
            <ReferenceField source="account_id" reference="accounts"/>
            <TextField source="delete_state"/>
            <TextField source="updated_at"/>
        </Datagrid>
    </List>
);