// in src/users.js
import * as React from "react";
import {List, Datagrid, TextField, NumberField, BooleanField, TextInput, ImageField, ReferenceField, ReferenceInput, SelectInput, BooleanInput, BulkUpdateButton, BulkDeleteButton} from 'react-admin';
import {JsonField} from "react-admin-json-view";
import {DeleteStateInput} from "../common/DeleteStateInput";
import {SaledrumPagination} from "../SaledrumPagination";
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <ReferenceInput label="Account" source="account_id" reference="accounts" alwaysOn/>,
    <DeleteStateInput label="Delete state" alwaysOn />,
    <ReferenceInput label="Channel" source="channel_id" reference="channels" alwaysOn/>,
    <SelectInput label="Channel Status" source="channel_status" alwaysOn choices={[
                {id: 'active', name: 'Active'},
                {id: 'inactive', name: 'Inactive'},
                {id: 'suppressed', name: 'Suppressed'},
                {id: 'inactive_failed', name: 'Failed'},
                {id: 'inactive_approval_needed', name: 'Approval Needed'},
                {id: 'inactive_high_price', name: 'High Price'},
    ]}/>,
    <SelectInput label="Is lowest seller" source="is_lowest_seller" alwaysOn choices={[
                {id: 0, name: 'No'},
                {id: 1, name: 'Yes'}]} />,
    <SelectInput label="Is buybox winner" source="is_buy_box_winner" alwaysOn choices={[
                {id: 0, name: 'No'},
                {id: 1, name: 'Yes'}]} />,
    <SelectInput label="Is at min" source="is_at_min" alwaysOn choices={[
                {id: 0, name: 'No'},
                {id: 1, name: 'Yes'}]} />,
    <SelectInput label="Is FBA" source="is_fba" alwaysOn choices={[
                {id: 0, name: 'No'},
                {id: 1, name: 'Yes'}]} />,
];
export const ListingList = () => (
    <List filters={postFilters} pagination={<SaledrumPagination/>} perPage={100} sort={{ field: 'id', order: 'DESC' }}>
       <Datagrid>
            <TextField source="id" />
            <ImageField source="images[0].src" />
            <TextField source="id_sku" />
            <TextField source="id_from_channel" />
            <TextField source="title" />
            <NumberField source="price_standard" />
            <NumberField source="qty" />
            <TextField source="channel_status" />
            <NumberField source="buybox_rank" />
            <NumberField source="price_min" />
            <NumberField source="price_max" />
            <NumberField source="price_base" />
            <NumberField source="channel_fee_total" />
            <NumberField source="margin_amount" />
            <NumberField source="margin_percent" />
            <BooleanField source="is_at_max" />
            <BooleanField source="is_at_min" />
            <BooleanField source="is_lowest_seller" />
            <BooleanField source="is_only_seller" />
            <BooleanField source="is_buy_box_winner" />
            <TextField source="delete_state" />
            <TextField source="created_at" />
            <ReferenceField source="account_id" reference="accounts"/>
            <ReferenceField source="channel_id" reference="channels"/>
        </Datagrid>
    </List>
);