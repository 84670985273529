import {Edit, SimpleForm, NumberInput, TextInput} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {JsonInput} from "react-admin-json-view";

export const CeleryBeatJobEdit = () => {
    return (
        <Edit label="Edit Celery Beat Job" mutationMode="pessimistic" component="div">
            <SimpleForm sanitizeEmptyValues>
                <NumberInput source="id"/>
                <TextInput source="name"/>
                <NumberInput source="interval_min"/>
                <JsonInput source="task_kwargs"/>
                <JsonInput source="last_error_data"/>
                <DeleteStateInput/>
            </SimpleForm>
        </Edit>
    )
};