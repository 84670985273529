// in src/users.js
import * as React from "react";
import {List, Datagrid, TextField, EmailField, TextInput, SelectInput, BooleanField, ReferenceField, ReferenceInput, BulkUpdateButton, BulkDeleteButton} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {SaledrumPagination} from "../SaledrumPagination";
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <ReferenceInput label="Account" source="account_id" reference="accounts" alwaysOn/>,
    <DeleteStateInput label="Delete state" alwaysOn />,
    <SelectInput label="Status" source="status" choices={[
                {id: 'invite_sent', name: 'Invite Sent'},
                {id: 'active', name: 'Active'},
                {id: 'deleted', name: 'Deleted'},
                {id: 'password_token_sent', name: 'Password Token Sent'},
                {id: 'suspended', name: 'Suspended'},
                {id: 'just_registered', name: 'Just Registered'},
            ]} alwaysOn/>,
];
const PostBulkActionButtons = () => (
    <>
        <BulkUpdateButton label="Suspend" data={{status: 'suspended'}} mutationMode="pessimistic"  />
        <BulkUpdateButton label="Activate" data={{status: 'active'}} mutationMode="pessimistic"  />
        <BulkUpdateButton label="Add demo account" data={{action: 'user_create_demo_account'}} mutationMode="pessimistic"  />
        <BulkDeleteButton />
    </>
);
export const UserList = () => (
    <List filters={postFilters} pagination={<SaledrumPagination/>} perPage={100} sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <EmailField source="email" />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="status" />
            <BooleanField source="ui_data.onboard_checklist.show" label="Onboard completed"/>
            <TextField source="delete_state" />
            <ReferenceField source="account_id" reference="accounts"/>
            <TextField source="created_at" />
        </Datagrid>
    </List>
);