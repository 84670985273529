import {Edit, SimpleForm, TextInput, SelectInput, DateTimeInput, NumberInput} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {JsonInput} from "react-admin-json-view";
import * as React from "react";

export const ApiRequestEdit = () => {
    return (
        <Edit label="Edit Api Request" mutationMode="pessimistic" component="div">
            <SimpleForm sanitizeEmptyValues>
                <SelectInput source="status" choices={[
                    {id: 'scheduled', name: 'Scheduled'},
                    {id: 'running', name: 'Running'},
                    {id: 'completed', name: 'Completed'},
                    {id: 'failed', name: 'Failed'},
                ]} />
                <JsonInput source="payload"/>
                <JsonInput source="response"/>

                <DateTimeInput source="scheduled_at"/>
                <DeleteStateInput/>
            </SimpleForm>
        </Edit>
    )
};