// in src/users.js
import * as React from "react";
import {List, Datagrid, TextField, EmailField, NumberField, BooleanField, TextInput, SelectInput, ReferenceField, ReferenceInput, BulkUpdateButton, BulkDeleteButton, useListContext, useUpdateMany} from 'react-admin';
import {JsonField} from "react-admin-json-view";
import {DeleteStateInput} from "../common/DeleteStateInput";
import {SaledrumPagination} from "../SaledrumPagination";
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <ReferenceInput label="Account" source="account_id" reference="accounts" alwaysOn/>,
    <DeleteStateInput label="Delete state" alwaysOn />,
    <DeleteStateInput label="Reprice Status" source="reprice_status" alwaysOn />,
    <DeleteStateInput label="Live Status" source="live_status" alwaysOn />,
];
const PostBulkActionButtons = () => (
    <>
        <BulkUpdateButton label="Pause Live Status" data={{live_status: 'paused'}} mutationMode="pessimistic"  />
        <BulkUpdateButton label="Activate Live Status" data={{live_status: 'active'}} mutationMode="pessimistic"  />
        <BulkUpdateButton label="Pause Reprice Status" data={{reprice_status: 'paused'}} mutationMode="pessimistic"  />
        <BulkUpdateButton label="Activate Reprice Status" data={{reprice_status: 'active'}} mutationMode="pessimistic"  />
        <BulkUpdateButton label="Check Amazon Connectivity" data={{action: 'channel_test_connectivity'}} mutationMode="pessimistic" />
        <BulkUpdateButton label="Assign Default Price Rule" data={{action: 'channel_assign_default_reprice_rules'}} mutationMode="pessimistic" />
        <BulkUpdateButton label="Remove Subscriptions" data={{action: 'channel_remove_subscriptions'}} mutationMode="pessimistic" />
        <BulkUpdateButton label="Add Subscriptions" data={{action: 'channel_add_subscriptions'}} mutationMode="pessimistic" />
        <BulkUpdateButton label="Remove SQS Messages" data={{action: 'channel_remove_sqs_messages'}} mutationMode="pessimistic" />
        <BulkUpdateButton label="Update System Tags" data={{action: 'channel_update_system_tags'}} mutationMode="pessimistic" />
        <BulkDeleteButton />
    </>
);
export const ChannelList = () => (
    <List filters={postFilters} pagination={<SaledrumPagination/>} perPage={100} sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />}>
       <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField source="account_id" reference="accounts"/>
            <TextField source="name" />
            <TextField source="channel_external_id"/>
            <TextField source="channel_type" />
            <TextField source="live_status" />
            <TextField source="reprice_status" />
            <TextField source="region" />
            <TextField source="total_listings" />
            <TextField source="total_listings_missing_minmax" />
            <TextField source="created_at" />
        </Datagrid>
    </List>
);