import { Menu } from 'react-admin';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import LabelIcon from '@mui/icons-material/Label';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SellIcon from '@mui/icons-material/Sell';
import CollectionsIcon from '@mui/icons-material/Collections';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SyncIcon from '@mui/icons-material/Sync';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import HubIcon from '@mui/icons-material/Hub';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import WebhookIcon from '@mui/icons-material/Webhook';

export const SaledrumMenu = () => (
    <Menu>
        <Menu.Item to="/users" primaryText="Users" leftIcon={<PeopleAltIcon />}/>
        <Menu.Item to="/accounts" primaryText="Account" leftIcon={<AccountCircleIcon />}/>
        <Menu.Item to="/celery-beat-jobs" primaryText="Async Workers" leftIcon={<AutoAwesomeMotionIcon />}/>
        <Menu.Item to="/channels" primaryText="Channels" leftIcon={<StorefrontIcon />}/>
        <Menu.Item to="/channel-jobs" primaryText="Channel Jobs" leftIcon={<CollectionsIcon />}/>
        <Menu.Item to="/channel-product-prices" primaryText="Channel Product Prices" leftIcon={<MonetizationOnIcon />}/>
        <Menu.Item to="/listings" primaryText="Listings" leftIcon={<SellIcon />}/>
        <Menu.Item to="/listing-jobs" primaryText="Listing Jobs" leftIcon={<SyncIcon />}/>
        <Menu.Item to="/order-items" primaryText="Orders" leftIcon={<PointOfSaleIcon />}/>
        <Menu.Item to="/reprice-rules" primaryText="Reprice Rules" leftIcon={<AutoFixHighIcon />}/>
        <Menu.Item to="/saved-searches" primaryText="Saved Searches" leftIcon={<SavedSearchIcon />}/>
        <Menu.Item to="/uploads" primaryText="Uploads" leftIcon={<CloudUploadIcon />}/>
        <Menu.Item to="/user-activities" primaryText="User Activity" leftIcon={<WebStoriesIcon />}/>
        <Menu.Item to="/virtual-product-ingestions" primaryText="VP Ingestions" leftIcon={<HubIcon />}/>
        <Menu.Item to="/virtual-product-restrictions" primaryText="VP Restrictions" leftIcon={<RemoveCircleIcon />}/>
        <Menu.Item to="/virtual-products" primaryText="Virtual Products" leftIcon={<RemoveCircleIcon />}/>
        <Menu.Item to="/api-requests" primaryText="Api Requests" leftIcon={<WebhookIcon />}/>
    </Menu>
);