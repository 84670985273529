// in src/users.js
import * as React from "react";
import {List, Datagrid, TextField, NumberField, BooleanField, TextInput, ImageField, ReferenceField, ReferenceInput, SelectInput} from 'react-admin';
import {JsonField, JsonInput} from "react-admin-json-view";
import {DeleteStateInput} from "../common/DeleteStateInput";
import {SaledrumPagination} from "../SaledrumPagination";
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <ReferenceInput label="Account" source="account_id" reference="accounts" alwaysOn/>,
    <DeleteStateInput label="Delete state" alwaysOn />,
];
export const UploadList = () => (
    <List filters={postFilters} pagination={<SaledrumPagination/>} perPage={100}  sort={{ field: 'id', order: 'DESC' }}>
       <Datagrid>
            <TextField source="id" />
            <TextField source="type" />
            <TextField source="url"/>
            <TextField source="status" />
            <JsonField source="error_data" />
            <NumberField source="total_count" />
            <NumberField source="success_count" />
            <NumberField source="error_count" />
            <TextField source="created_at" />
            <ReferenceField source="account_id" reference="accounts"/>
            <ReferenceField source="channel_id" reference="channels"/>
        </Datagrid>
    </List>
);