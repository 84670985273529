import {Edit, SimpleForm, TextInput, SelectInput} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {JsonInput} from "react-admin-json-view";
import * as React from "react";

export const RepriceRuleEdit = () => {
    return (
        <Edit label="Edit Celery Beat Job" mutationMode="pessimistic" component="div">
            <SimpleForm sanitizeEmptyValues>
                <TextInput source="name"/>
                <SelectInput label="Type" source="type" choices={[
                    {id: 'competitor', name: 'Competitor'},
                    {id: 'min_max', name: 'Min Max'},
                    {id: 'stock', name: 'Stock'},
                    {id: 'competitor_product', name: 'Competitor Product'},
                ]} alwaysOn/>
                <JsonInput source="rule_data"/>
                <DeleteStateInput/>
            </SimpleForm>
        </Edit>
    )
};