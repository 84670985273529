import {BooleanInput, DateTimeInput, Edit, SimpleForm, TextInput, SelectInput} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {JsonInput} from "react-admin-json-view";

export const UserEdit = () => (
    <Edit label="Edit User" mutationMode="pessimistic">
        <SimpleForm>
            <TextInput source="email"/>
            <DeleteStateInput source="delete_state"/>
            <TextInput source="api_key"/>
            <TextInput source="api_secret"/>
            <TextInput source="first_name"/>
            <TextInput source="last_name"/>
            <TextInput source="password_token"/>
            <DateTimeInput source="password_token_expire_at"/>
            <SelectInput source="status" choices={[
                {id: 'invite_sent', name: 'Invite Sent'},
                {id: 'active', name: 'Active'},
                {id: 'deleted', name: 'Deleted'},
                {id: 'password_token_sent', name: 'Password Token Sent'},
                {id: 'suspended', name: 'Suspended'},
                {id: 'just_registered', name: 'Just Registered'},
            ]}/>
            <JsonInput source="ui_data"/>
            <BooleanInput source="ui_data.onboard_checklist.show" label="Show UI onboard widget"/>
        </SimpleForm>
    </Edit>
);