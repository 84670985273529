import {SelectInput, DateTimeInput, Edit, SimpleForm, NumberInput} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import * as React from "react";

export const ChannelJobEdit = () => (
    <Edit label="Edit Account" mutationMode="pessimistic">
        <SimpleForm sanitizeEmptyValues>
            <DateTimeInput source="kwargs.dataStartTime"/>
            <DateTimeInput source="last_run_at"/>
            <DateTimeInput source="next_run_at"/>
            <NumberInput source="run_interval"/>
            <NumberInput source="retry_cnt"/>
            <DeleteStateInput/>
            <SelectInput source="status" choices={[
                {id: 'pending', name: 'Pending'},
                {id: 'processing', name: 'Processing'},
                {id: 'error', name: 'Error'},
            ]}/>
            <SelectInput source="type" choices={[
                {id: 'amazon_report_qty_price', name: 'amazon_report_qty_price'},
                {id: 'amazon_report_listing_active', name: 'amazon_report_listing_active'},
                {id: 'amazon_report_qty_fba', name: 'amazon_report_qty_fba'},
                {id: 'amazon_report_all_orders', name: 'amazon_report_all_orders'},
                {id: 'amazon_report_listing_inactive', name: 'amazon_report_listing_inactive'},
                {id: 'amazon_report_listing_suppressed', name: 'amazon_report_listing_suppressed'},
                {id: 'amazon_report_listing_defect', name: 'amazon_report_listing_defect'},
            ]}/>
        </SimpleForm>
    </Edit>
);