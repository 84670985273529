import {Edit, SimpleForm, TextInput, SelectInput, DateTimeInput, NumberInput} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {JsonInput} from "react-admin-json-view";
import * as React from "react";

export const VirtualProductIngestionEdit = () => {
    return (
        <Edit label="Edit Saved Search" mutationMode="pessimistic" component="div">
            <SimpleForm sanitizeEmptyValues>
                <TextInput source="name"/>
                <TextInput source="url"/>

                <SelectInput source="strategy" choices={[
                    {id: 'walmart', name: 'Walmart'},
                    {id: 'bestbuy', name: 'BestBuy'},
                ]}/>
                <SelectInput source="status" choices={[
                    {id: 'ready', name: 'Ready'},
                    {id: 'processing', name: 'Processing'},
                    {id: 'paused', name: 'Paused'},
                    {id: 'error', name: 'Error'},
                ]}/>
                <NumberInput source="fetch_interval"/>
                <NumberInput source="retry_cnt"/>
                <DateTimeInput source="next_run_at"/>
                <JsonInput source="pagination"/>
                <DeleteStateInput/>
            </SimpleForm>
        </Edit>
    )
};