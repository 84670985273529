// in src/users.js
import * as React from "react";
import {List, Datagrid, TextField, NumberField, ReferenceField, ReferenceInput, SelectInput, BooleanField, TextInput} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {SaledrumPagination} from "../SaledrumPagination";
const postFilters = [
     <TextInput label="Search" source="q" alwaysOn />,
    <ReferenceInput label="Account" source="account_id" reference="accounts" alwaysOn/>,
    <ReferenceInput label="Channel" source="channel_id" reference="channels" alwaysOn/>,
    <DeleteStateInput label="Delete state" alwaysOn />,

    <SelectInput label="Status" source="status" choices={[
                {id: 'active', name: 'Active'},
                {id: 'pending', name: 'Pending'},
                {id: 'processing', name: 'Processing'},
                {id: 'error', name: 'Error'},
            ]} alwaysOn/>,
];
export const ListingJobList = () => (
    <List filters={postFilters} pagination={<SaledrumPagination/>} perPage={100} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <ReferenceField source="listing_id" reference="listings"/>
            <TextField source="channel_product_id"/>
            <TextField source="listing_channel_id"/>
            <NumberField source="price_channel"/>
            <NumberField source="qty_channel"/>
            <TextField source="status"/>
            <TextField source="qty_status"/>
            <TextField source="price_status"/>
            <TextField source="update_status"/>
            <TextField source="delete_status"/>
            <TextField source="process_data"/>
            <TextField source="virtual_product_id"/>
            <TextField source="virtual_product_strategy"/>
            <BooleanField source="virtual_in_stock"/>
            <ReferenceField source="account_id" reference="accounts"/>
            <ReferenceField source="channel_id" reference="channels"/>
            <TextField source="delete_state"/>
            <TextField source="updated_at"/>
        </Datagrid>
    </List>
);