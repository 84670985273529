// in src/users.js
import * as React from "react";
import {List, Datagrid, TextField, TextInput, NumberField, BulkUpdateButton, BulkDeleteButton} from 'react-admin';
import {JsonField} from "react-admin-json-view";
import {SaledrumPagination} from "../SaledrumPagination";
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <TextInput label="Account" source="account_id" />,
];
const PostBulkActionButtons = () => (
    <>
        <BulkUpdateButton label="Pause" data={{delete_state: 'paused'}} mutationMode="pessimistic"  />
        <BulkUpdateButton label="Activate" data={{delete_state: 'active'}} mutationMode="pessimistic"  />
         <BulkUpdateButton label="Reset Errors" data={{last_error_data: {}}}  mutationMode="pessimistic" />
        <BulkDeleteButton />
    </>
);
export const CeleryBeatJoblist = () => (
    <List filters={postFilters} pagination={<SaledrumPagination/>} perPage={100} bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <NumberField source="interval_min" />
            <TextField source="delete_state" />
            <JsonField source="task_kwargs"/>
            <JsonField source="last_error_data"/>
            <TextField source="last_run_at"/>
            <TextField source="last_error_at"/>
        </Datagrid>
    </List>
);