import {BooleanInput, DateTimeInput, Edit, SimpleForm, TextInput, SelectInput} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {JsonInput} from "react-admin-json-view";

export const AccountEdit = () => (
    <Edit label="Edit Account" mutationMode="pessimistic">
        <SimpleForm sanitizeEmptyValues={true}>
            <TextInput source="email"/>
            <JsonInput source="current_rate_limit"/>
            <JsonInput source="max_rate_limit"/>
            <JsonInput source="errors"/>
            <DeleteStateInput/>
            <BooleanInput source="is_demo"/>
            <BooleanInput source="is_pro"/>
            <BooleanInput source="is_expired"/>
            <DateTimeInput source="trial_expiration_at"/>
        </SimpleForm>
    </Edit>
);