import {SelectInput, DateTimeInput, Edit, SimpleForm, NumberInput, TextField , TextInput, Datagrid} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import * as React from "react";
import {JsonInput} from "react-admin-json-view";

export const ListingJobEdit = () => (
    <Edit label="Edit Listing Job" mutationMode="pessimistic">
        <SimpleForm sanitizeEmptyValues>
             <JsonInput source="process_data"/>
             <JsonInput source="listing_data"/>
             <NumberInput source="price_channel"/>
             <NumberInput source="qty_channel"/>
             <TextInput source="virtual_product_id"/>
             <TextInput source="virtual_product_strategy"/>

            <SelectInput source="status" choices={[
                {id: 'draft', name: 'Draft'},
                {id: 'pending', name: 'Pending'},
                {id: 'processing', name: 'Processing'},
                {id: 'active', name: 'Active'},
                {id: 'error', name: 'Error'},
            ]}/>
            <SelectInput source="qty_status" choices={[
                {id: 'draft', name: 'Draft'},
                {id: 'pending', name: 'Pending'},
                {id: 'processing', name: 'Processing'},
                {id: 'active', name: 'Active'},
                {id: 'error', name: 'Error'},
            ]}/>
            <SelectInput source="price_status" choices={[
                {id: 'draft', name: 'Draft'},
                {id: 'pending', name: 'Pending'},
                {id: 'processing', name: 'Processing'},
                {id: 'active', name: 'Active'},
                {id: 'error', name: 'Error'},
            ]}/>
            <SelectInput source="update_status" choices={[
                {id: 'draft', name: 'Draft'},
                {id: 'pending', name: 'Pending'},
                {id: 'processing', name: 'Processing'},
                {id: 'active', name: 'Active'},
                {id: 'error', name: 'Error'},
            ]}/>
            <SelectInput source="delete_status" choices={[
                {id: 'draft', name: 'Draft'},
                {id: 'pending', name: 'Pending'},
                {id: 'processing', name: 'Processing'},
                {id: 'active', name: 'Active'},
                {id: 'error', name: 'Error'},
            ]}/>
            <SelectInput source="create_status" choices={[
                {id: 'draft', name: 'Draft'},
                {id: 'pending', name: 'Pending'},
                {id: 'processing', name: 'Processing'},
                {id: 'active', name: 'Active'},
                {id: 'error', name: 'Error'},
            ]}/>
        </SimpleForm>
    </Edit>
);