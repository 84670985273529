// in src/users.js
import * as React from "react";
import {List, Datagrid, TextField, EmailField, BooleanField, NullableBooleanInput, TextInput, BulkUpdateButton, BulkDeleteButton} from 'react-admin';
import {JsonField} from "react-admin-json-view";
import {SaledrumPagination} from "../SaledrumPagination";
import {DeleteStateInput} from "../common/DeleteStateInput";

const postFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    <NullableBooleanInput source="is_demo" alwaysOn/>,
    <NullableBooleanInput source="is_pro" alwaysOn/>,
    <NullableBooleanInput source="is_expired" alwaysOn/>,
    <DeleteStateInput/>
];
const PostBulkActionButtons = () => (
    <>
        <BulkUpdateButton label="Reset Errors" data={{errors: {}}} mutationMode="pessimistic"/>
        <BulkUpdateButton label="Pause" data={{delete_state: 'paused'}} mutationMode="pessimistic"/>
        <BulkUpdateButton label="Activate" data={{delete_state: 'active'}} mutationMode="pessimistic"/>
        <BulkUpdateButton label="Expire" data={{is_expired: true}} mutationMode="pessimistic"/>
        <BulkUpdateButton label="Increase Limits" data={{
            current_rate_limit: {
                listing_all: 50000,
                order_month: 200,
                reprice_hour: 6000,
                reprice_month: 100000,
            }
        }} mutationMode="pessimistic"/>
        <BulkDeleteButton/>
    </>
);
export const AccountList = () => (
    <List filters={postFilters} pagination={<SaledrumPagination/>} perPage={100} sort={{field: 'id', order: 'DESC'}} bulkActionButtons={<PostBulkActionButtons/>}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <EmailField source="email"/>
            <JsonField source="current_rate_limit"/>
            <JsonField source="max_rate_limit"/>
            <TextField source="errors"/>
            <BooleanField source="is_demo"/>
            <BooleanField source="is_pro"/>
            <BooleanField source="is_expired"/>
            <TextField source="delete_state"/>
            <TextField source="created_at"/>
        </Datagrid>
    </List>
);