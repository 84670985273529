// in src/users.js
import * as React from "react";
import {List, Datagrid, TextField, EmailField, FileField, TextInput, SelectInput, DateTimeField, NumberField, ReferenceField, ReferenceInput, SimpleForm, BulkUpdateButton, BulkDeleteButton} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {JsonField} from "react-admin-json-view";
import {SaledrumPagination} from "../SaledrumPagination";

const postFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    <ReferenceInput label="Account" source="account_id" reference="accounts" alwaysOn/>,
    <ReferenceInput label="Channel" source="channel_id" reference="channels" alwaysOn/>,
    <SelectInput source="resource" choices={[
        {id: 'MwsReportType.REPORT_ORDERS_ALL', name: 'All Orders'},
        {id: 'MwsReportType.REPORT_LISTINGS_ACTIVE', name: 'Active Listings'},
        {id: 'MwsReportType.REPORT_LISTINGS_QTY_PRICE', name: 'Price/Qty'},
        {id: 'MwsReportType.REPORT_LISTINGS_INACTIVE', name: 'Inactive Listings'},
        {id: 'MwsReportType.REPORT_LISTINGS_QTY_FBA', name: 'FBA Qty'},
        {id: 'add_subscription', name: 'Add Subscription'},
    ]} alwaysOn/>,
    <SelectInput source="status" choices={[
        {id: 'scheduled', name: 'Scheduled'},
        {id: 'running', name: 'Running'},
        {id: 'completed', name: 'Completed'},
        {id: 'failed', name: 'Failed'},
    ]} alwaysOn/>
];
const PostBulkActionButtons = () => (
    <>
        <BulkUpdateButton label="Scheduled" data={{status: 'scheduled'}} mutationMode="pessimistic"  />
        <BulkUpdateButton label="Completed" data={{status: 'completed'}} mutationMode="pessimistic"  />
        <BulkUpdateButton label="Failed" data={{status: 'failed'}} mutationMode="pessimistic"  />
        <BulkUpdateButton label="Get Report Doc" data={{action: 'api_request_get_report_document'}} mutationMode="pessimistic" />
        <BulkDeleteButton />
    </>
);
export const ApiRequestList = () => (
    <List filters={postFilters} pagination={<SaledrumPagination/>} perPage={100}  sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="request_channel_id"/>
            <TextField source="resource"/>
            <TextField source="status"/>
            <TextField source="scheduled_at"/>
            <TextField source="start_at"/>
            <TextField source="end_at"/>
            <JsonField source="payload"/>
            <TextField source="response"/>
            <ReferenceField source="ref_job_id" reference="channel-jobs"/>
            <ReferenceField source="account_id" reference="accounts"/>
            <ReferenceField source="channel_id" reference="channels"/>
            <TextField source="created_at"/>
            <TextField source="delete_state"/>
        </Datagrid>
    </List>
);