import {Edit, SimpleForm, TextInput, SelectInput} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {JsonInput} from "react-admin-json-view";
import * as React from "react";

export const SavedSearchEdit = () => {
    return (
        <Edit label="Edit Saved Search" mutationMode="pessimistic" component="div">
            <SimpleForm sanitizeEmptyValues>
                <TextInput source="name"/>
                <JsonInput source="search_input"/>
                <DeleteStateInput/>
            </SimpleForm>
        </Edit>
    )
};