// in src/users.js
import * as React from "react";
import {List, Datagrid, TextField, EmailField, TextInput, SelectInput, DateTimeField, NumberField, ReferenceField, ReferenceInput, SimpleForm, BulkUpdateButton, BulkDeleteButton, BooleanField} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {JsonField} from "react-admin-json-view";
import {SaledrumPagination} from "../SaledrumPagination";

const postFilters = [
    <TextInput label="UPC" source="id" alwaysOn/>,
    <TextInput label="ASIN" source="id_amazon" alwaysOn/>,
    <TextInput label="Walmart Id" source="id_walmart" alwaysOn/>,
];
export const VirtualProductList = () => (
    <List filters={postFilters} pagination={<SaledrumPagination/>} perPage={100} >
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="id_amazon"/>
            <TextField source="id_walmart"/>
            <BooleanField source="amazon_in_stock"/>
            <BooleanField source="walmart_in_stock"/>
            <NumberField source="amazon_price"/>
            <NumberField source="walmart_price"/>
            <NumberField source="standard_margin_amount"/>
            <NumberField source="standard_margin_percent"/>
            <NumberField source="min_margin_amount"/>
            <NumberField source="min_percent_percent"/>
            <NumberField source="max_margin_amount"/>
            <NumberField source="max_percent_percent"/>

            <TextField source="updated_at"/>
        </Datagrid>
    </List>
);