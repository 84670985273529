// in src/App.js
import * as React from "react";
import {fetchUtils, Admin, Resource, ListGuesser} from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import {UserList} from "./users/UserList";
import {AccountList} from "./accounts/AccountList";
import {SaledrumLayout} from "./SaledrumLayout";
import {UserEdit} from "./users/UserEdit";
import {AccountEdit} from "./accounts/AccountEdit";
import {CeleryBeatJoblist} from "./celery-beat-jobs/CeleryBeatJoblist";
import {CeleryBeatJobEdit} from "./celery-beat-jobs/CeleryBeatJobEdit";
import {ChannelEdit} from "./channels/ChannelEdit";
import {ChannelList} from "./channels/ChannelList";
import {ChannelJobList} from "./channel-jobs/ChannelJobList";
import {ChannelJobEdit} from "./channel-jobs/ChannelJobEdit";
import {ChannelProductPriceList} from "./channel-product-prices/ChannelProductPriceList";
import {ChannelProductPriceEdit} from "./channel-product-prices/ChannelProductPriceEdit";
import {ListingList} from "./listings/ListingList";
import {ListingJobEdit} from "./listing-jobs/ListingJobEdit";
import {ListingJobList} from "./listing-jobs/ListingJobList";
import {OrderItemList} from "./order-items/OrderItemList";
import {RepriceRuleEdit} from "./reprice-rules/RepriceRuleEdit";
import {RepriceRuleList} from "./reprice-rules/RepriceRuleList";
import {SavedSearchEdit} from "./saved-searches/SavedSearchEdit";
import {SavedSearchList} from "./saved-searches/SavedSearchList";
import {UploadList} from "./uploads/UploadList";
import {UserActivityList} from "./user-activities/UserActivityList";
import {VirtualProductIngestionEdit} from "./virtual-product-ingestions/VirtualProductIngestionEdit";
import {VirtualProductIngestionList} from "./virtual-product-ingestions/VirtualProductIngestionList";
import {VirtualProductRestrictionList} from "./virtual-product-restrictions/VirtualProductRestrictionList";
import {ApiRequestList} from "./api-requests/ApiRequestList";
import {ApiRequestEdit} from "./api-requests/ApiRequestEdit";
import authProvider from "./authProvider";
import SaledrumDashboard from "./SaledrumDashboard";
import {VirtualProductRestrictionAdd} from "./virtual-product-restrictions/VirtualProductRestrictionAdd";
import {CeleryBeatJobAdd} from "./celery-beat-jobs/CeleryBeatJobAdd";
import {VirtualProductList} from "./virtual-products/VirtualProductList";
import {VirtualProductEdit} from "./virtual-products/VirtualProductEdit";

const httpClient = (url, options = {}) => {
    const token = localStorage.getItem('admin-token');
    options.headers = new Headers({Accept: 'application/json', 'Admin-Token': token});
    return fetchUtils.fetchJson(url, options);
};
const {REACT_APP_API_URL} = process.env;
const dataProvider = simpleRestProvider(REACT_APP_API_URL, httpClient);
const App = () => (
    <Admin layout={SaledrumLayout} dataProvider={dataProvider} authProvider={authProvider} title="SaleDrum" dashboard={SaledrumDashboard} disableTelemetry requireAuth>
        <Resource name="users" list={UserList} edit={UserEdit}/>
        <Resource name="accounts" list={AccountList} edit={AccountEdit} recordRepresentation={(record) => `${record.id} (${record.email})`}/>
        <Resource name="channels" list={ChannelList} edit={ChannelEdit} recordRepresentation={(record) => `${record.id}-${record.name} (${record.channel_external_id})`}/>
        <Resource name="celery-beat-jobs" list={CeleryBeatJoblist} edit={CeleryBeatJobEdit} create={CeleryBeatJobAdd}/>
        <Resource name="channel-jobs" list={ChannelJobList} edit={ChannelJobEdit}/>
        <Resource name="channel-product-prices" list={ChannelProductPriceList} edit={ChannelProductPriceEdit}/>
        <Resource name="listings" list={ListingList} recordRepresentation={(record) => `${record.title} (${record.id})`}/>
        <Resource name="listing-jobs" list={ListingJobList} edit={ListingJobEdit}/>
        <Resource name="listing-reprice-activities" list={ListGuesser}/>
        <Resource name="listing-reprice-rules" list={ListGuesser}/>
        <Resource name="order-items" list={OrderItemList}/>
        <Resource name="order-items" list={ListGuesser}/>
        <Resource name="reprice-rules" list={RepriceRuleList} edit={RepriceRuleEdit}/>
        <Resource name="saved-searches" list={SavedSearchList} edit={SavedSearchEdit}/>
        <Resource name="uploads" list={UploadList}/>
        <Resource name="user-activities" list={UserActivityList}/>
        <Resource name="virtual-product-ingestions" list={VirtualProductIngestionList} edit={VirtualProductIngestionEdit}/>
        <Resource name="virtual-product-restrictions" list={VirtualProductRestrictionList} create={VirtualProductRestrictionAdd}/>
        <Resource name="virtual-products" list={VirtualProductList} edit={VirtualProductEdit}/>
        <Resource name="api-requests" list={ApiRequestList} edit={ApiRequestEdit}/>
    </Admin>
);


export default App;