// in src/users.js
import * as React from "react";
import {List, Datagrid, TextField, EmailField, TextInput, SelectInput, DateTimeField, NumberField, ReferenceField, ReferenceInput} from 'react-admin';
import {DeleteStateInput} from "../common/DeleteStateInput";
import {JsonField} from "react-admin-json-view";
import {SaledrumPagination} from "../SaledrumPagination";
const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <ReferenceInput label="Account" source="account_id" reference="accounts" alwaysOn/>,
    <SelectInput label="Type" source="type" choices={[
                {id: 'competitor', name: 'Competitor'},
                {id: 'min_max', name: 'Min Max'},
                {id: 'stock', name: 'Stock'},
                {id: 'competitor_product', name: 'Competitor Product'},
            ]} alwaysOn/>,
];
export const RepriceRuleList = () => (
    <List filters={postFilters} pagination={<SaledrumPagination/>} perPage={100} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="type" />
            <JsonField source="rule_data"/>
            <TextField source="created_at"/>
            <ReferenceField source="account_id" reference="accounts"/>
            <TextField source="delete_state" />
        </Datagrid>
    </List>
);